// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "map", "latitude", "longitude", "type"];
  connect() {
    console.log("-----connect-----");
    if (!google || typeof google != "undefined") {
      this.initMap();
    }
  } 
  initMap() {
    // type is show or edit to check if the field is editable
    console.log("initMap called -------", this.data.get("type") === "edit");
    const type = this.data.get("type") // all the marker update only for edit, not in show
    const lat = this.data.get("latitude");
    const lng = this.data.get("longitude");
    console.log(lat, lng);
    const zoom = 17;
    this.map = new google.maps.Map(this.mapTarget, {
      // from data-target maps.map > take `map` target
      center: new google.maps.LatLng(
        lat || 39.5, // if no value set fixed in US region
        lng || -98.35
      ),
      zoom: lat == null ? 4 : zoom,
    });
    let latlngPos = lat && lng ? new google.maps.LatLng(lat, lng) : null;

    this.marker = new google.maps.Marker({
      map: this.map,
      position: latlngPos,
      draggable: type === "edit" ? true : false,
      // anchorPoint: new google.maps.Point(0, -29),
    });
    if (type === "edit") {
      this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget);
      this.autocomplete.bindTo("bounds", this.map);
      this.autocomplete.setFields([
        "address_components",
        "geometry", // latlng info
        "icon",
        "name",
      ]); // how the list in search shows
      this.autocomplete.addListener("place_changed", this.placeChanged);

      google.maps.event.addListener(this.map, "click", this.clickEvent);
      google.maps.event.addListener(this.marker, "dragend", this.clickEvent);

    }
  }
  
  clickEvent = (event) => {
    const location = event.latLng;
    
    this.marker.setPosition(location);
    this.marker.setVisible(true);
    this.latitudeTarget.value = location.lat();
    this.longitudeTarget.value = location.lng();
  };
  
  placeChanged = () => {
    let place = this.autocomplete.getPlace();

    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`);
      return;
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport);
    } else {
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(zoom);
    }

    this.marker.setPosition(place.geometry.location);
    this.marker.setVisible(true);

    this.latitudeTarget.value = place.geometry.location.lat();
    this.longitudeTarget.value = place.geometry.location.lng();
  };

  keydown = (event) => {
    if (event.key == "Enter") {
      // don't submit for on enter key when searching for map
      event.preventDefault();
    }
  };
}
